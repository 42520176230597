<template>
  <v-snackbar
    v-if="autoTimeout"
    v-model="snackbarAuto"
    right
    multi-line
    :color="color"
    :timeout="timeout"
  >
    {{ text }}
  </v-snackbar>
  <v-snackbar v-else v-model="snackbar" right multi-line :color="color">
    {{ text }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'SnackBar',
  data() {
    return {
      snackbar: false,
      snackbarAuto: false,
      text: '',
      color: '',
      timeout: 3000,
      autoTimeout: true
    }
  },
  mounted() {
    this.emitter.on('success', (text) => {
      this.snackbarAuto = true
      this.color = 'success'
      this.text = text
    })
    this.emitter.on('error', (text) => {
      this.snackbarAuto = true
      this.color = 'error'
      this.text = text
    })
    this.emitter.on('info', (text) => {
      this.snackbarAuto = true
      this.color = 'dark'
      this.text = text
    })
    this.emitter.on('snackbar_Open', (text) => {
      this.autoTimeout = false
      this.snackbar = true
      this.color = 'success'
      this.text = text
    })
    this.emitter.on('snackbar_Close', () => {
      this.snackbar = false
      this.autoTimeout = true
    })
  }
}
</script>
