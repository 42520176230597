import times from 'lodash/times'
import concat from 'lodash/concat'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import some from 'lodash/some'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import debounce from 'lodash/debounce'
import { MEDIA_TYPE } from '@constants'

import { GroupClusterService } from '@/services'

export default {
  data() {
    return {
      key: 0,
      lightboxMedia: null,
      currentIndex: null,
      isDragging: false,

      errors: {},
      rank: {
        min: null,
        max: null,
        affectedRanks: []
      },
      gridItems: [],
      mediaItems: [],
      _mediaItems: [],
      showLightBox: false,
      touchCount: 0,
      lastTouchTime: 0,
      touchTimeout: null,
      dialog: false,
      footerElement: null,
      lastScrollTop: 0,
      params: {
        page: 1,
        limit: 16
      },
      totalPages: 1,
      lastPageFull: false,
      selectedImages: 0,
      selectedItemsArray: [],
      totalMedia: 0,
      intersectionObserver: null,
      showLoadMoreBtn: true,
      enabledChangeRank: false,
      lastSelectedItem: null
    }
  },

  beforeRouteLeave(to, from, next) {
    this.destroyIntersectionObserver()
    next()
  },

  beforeDestroy() {
    if (this.intersectionObserver) {
      this.destroyIntersectionObserver()
    }
  },

  methods: {
    findMinMaxNumbersWithIndexes(affectedRanks) {
      return affectedRanks.reduce(
        (result, obj) => {
          ;['oldIndex', 'newIndex'].forEach((key) => {
            if (obj[key] < result.min.value) {
              result.min = { value: obj[key], index: key }
            }
            if (obj[key] > result.max.value) {
              result.max = { value: obj[key], index: key }
            }
          })
          return result
        },
        {
          min: { value: Number.MAX_VALUE, index: '' },
          max: { value: Number.MIN_VALUE, index: '' }
        }
      )
    },

    retrieveAffectedRanks(event) {
      if (event.newIndicies?.length) {
        event.newIndicies.forEach((_item) => {
          const sortableIndex = _item.multiDragElement?.sortableIndex
          const newIndex = _item.index
          const oldIndexMedia = this._mediaItems[sortableIndex]
          const newIndexMedia = this._mediaItems[newIndex]
          if (oldIndexMedia && newIndexMedia) {
            this.rank.affectedRanks.push({
              oldIndex: oldIndexMedia.rank,
              newIndex: newIndexMedia.rank
            })
          }
        })
      } else {
        const oldIndexMedia = this._mediaItems[event.oldIndex]
        const newIndexMedia = this._mediaItems[event.newIndex]
        if (oldIndexMedia && newIndexMedia) {
          this.rank.affectedRanks.push({
            oldIndex: oldIndexMedia.rank,
            newIndex: newIndexMedia.rank
          })
        }
      }
      if (this.rank?.affectedRanks?.length) {
        const { min, max } = this.findMinMaxNumbersWithIndexes(this.rank.affectedRanks)
        this.rank.min = min
        this.rank.max = max
        if (min.value != max.value) {
          this.changeRank()
        }
      }
    },
    async onImageScroll() {
      if (!this.showLoadMoreBtn && this.totalPages > this.params.page && !this.$loading.spinner) {
        this.params.page++
        await this.getMedias()
      }
    },
    getColumns() {
      const length = this.mediaItems?.length >= 1 ? this.mediaItems?.length : 4
      this.gridItems = times(length, (x) => x)
    },
    openModal(media) {
      this.showLightBox = true
      this.lightboxMedia = media
    },
    closeLightBox(val) {
      this.showLightBox = val
      this.lightboxMedia = null
    },
    dragStart(index) {
      this.currentIndex = index
      this.isDragging = true
    },
    dragEnd() {
      this.currentIndex = null
      this.isDragging = false
      this.key++
      this.changeRank()
    },

    resetRank() {
      this.rank = {
        min: null,
        max: null,
        affectedRanks: []
      }
    },

    selectItemToggle(event) {
      const itemCard = event?.target?.closest('.item-card')
      if (itemCard && itemCard.classList) {
        if (itemCard.classList.contains('selected')) {
          itemCard.classList.remove('selected')
        } else {
          itemCard.classList.add('selected')
        }
      }
    },

    handleClick(event, itemData, target = 'lightBox', index = 0) {
      if (['location.edit', 'location.create'].includes(this.$route?.name)) {
        const DOUBLE_TAP_DELAY = 300 // ms
        const currentTime = new Date().getTime()
        const tapLength = currentTime - this.lastTouchTime

        if (tapLength < DOUBLE_TAP_DELAY && tapLength > 0 && this.sortable.option('disabled')) {
          if (this.touchTimeout) {
            window.clearTimeout(this.touchTimeout)
            this.touchTimeout = null
          }
          const now = new Date().getTime()
          if (target == 'openGroup' && itemData) {
            this.$emit('openGroup', itemData)
          } else if (now - this.lastTouchTime < 300 && target == 'lightBox') {
            this.openModal(itemData)
          }
        } else {
          this.touchTimeout = window.setTimeout(() => {
            if (this.sortable.option('disabled')) this.selectItemToggle(event)
            this.getSelectedItems(event)

            this.touchTimeout = null
          }, DOUBLE_TAP_DELAY)
        }

        this.lastTouchTime = currentTime
      } else if (target == 'openDocument' && itemData) {
        this.openDocument(itemData)
      } else {
        if (target == 'openGroup' && itemData) {
          this.$emit('openGroup', itemData)
        }
      }
    },

    onTouchStart(index, target = 'lightBox', itemData = null) {
      this.touchCount++
      if (this.touchCount === 1) {
        this.touchTimeout = setTimeout(() => {
          this.touchCount = 0
        }, 300)
      } else if (this.touchCount === 2) {
        clearTimeout(this.touchTimeout)
        this.touchCount = 0
        const now = new Date().getTime()
        if (target == 'openGroup' && itemData) {
          this.$emit('openGroup', itemData)
        } else if (now - this.lastTouchTime < 300) {
          this.openModal(index)
        }
        this.lastTouchTime = now
      }
    },
    onTouchEnd() {
      if (this.touchTimeout > 299) {
        clearTimeout(this.touchTimeout)
        this.touchCount = 0
      }
    },

    getSelectedItems(event = null) {
      let sortableContentIdsArray = ['groupList', 'mediaList', 'documentsList']
      sortableContentIdsArray = sortableContentIdsArray.filter((item) => item !== this.sortableId)

      sortableContentIdsArray?.forEach((id) => {
        const gridContainer = document.getElementById(id)
        const gridDivs = gridContainer?.querySelectorAll('div[data-id]')
        if (gridDivs?.length) {
          gridDivs?.forEach((div) => {
            const dataId = div.getAttribute('data-id')
            if (
              includes(this.selectedItemIdsArray, dataId) &&
              !div.classList.contains('selected')
            ) {
              div.classList.add('selected')
            }
          })
        }
      })

      const listParent = document.getElementById(this.sortableId)
      if (listParent) {
        const allDivs = Array.from(listParent.querySelectorAll('div[data-id]'))

        if (event && event.shiftKey && this.lastSelectedItem) {
          // Shift key is pressed and we have a last selected item
          const currentIndex = allDivs.findIndex(
            (div) => div === event.target.closest('div[data-id]')
          )
          const lastIndex = allDivs.findIndex(
            (div) => div.getAttribute('data-id') === this.lastSelectedItem
          )

          const start = Math.min(currentIndex, lastIndex)
          const end = Math.max(currentIndex, lastIndex)

          for (let i = start; i <= end; i++) {
            allDivs[i].classList.add('selected')
          }
        } else if (event) {
          // Single click without shift key
          const clickedDiv = event.target.closest('div[data-id]')
          if (clickedDiv) {
            this.lastSelectedItem = clickedDiv.getAttribute('data-id')
          }
        }

        const selectedDivs = listParent?.querySelectorAll('.selected')
        this.selectedImages = selectedDivs?.length || 0
        this.selectedItemsArray = Array.from(selectedDivs).map((item) =>
          item.getAttribute('data-id')
        )

        this.$emit('getSelectedItems', this.selectedItemsArray)
      }
    },

    handleTouchGetSelectedItems(event) {
      event.preventDefault()
      setTimeout(() => {
        this.getSelectedItems()
      }, 300)
    },
    deselectAll() {
      let sortableContentSectionsArray = ['groupList', 'mediaList', 'documentsList']

      sortableContentSectionsArray?.forEach((id) => {
        const listParent = document.getElementById(id)
        if (listParent) {
          const selectedDivs = listParent?.querySelectorAll('.selected')
          selectedDivs.forEach((div) => {
            div.classList.remove('selected')
          })
          this.selectedImages = 0
          this.selectedItemsArray = []
        }
      })
    },

    setMediaItems(mediaData) {
      this.mediaItems = concat(this.mediaItems, mediaData?.data)
      this.mediaItems = uniqBy(this.mediaItems, 'key')
      this._mediaItems = cloneDeep(this.mediaItems)
      this.lastPageFull = mediaData?.data?.length === this.params.limit
      this.totalMedia = mediaData?.count || 0
      if (mediaData?.totalPages) this.totalPages = mediaData?.totalPages
      this.getColumns()
    },

    registerObserver() {
      this.intersectionObserver = new IntersectionObserver(this.handleIntersect, {
        root: null,
        rootMargin: '0px 0px -10px 0px',
        threshold: 1
      })

      this.intersectionObserver.observe(this.$refs.endOfListIndicator)
    },

    destroyIntersectionObserver() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect()
        this.intersectionObserver = null
      }
    },

    async handleIntersect(entries) {
      const [entry] = entries
      if (entry.isIntersecting && this.totalPages > this.params.page) {
        this.params.page++
        await this.getLinkMedias(this.sharedVenueData?._id)
      }
    },

    async getMedias() {
      this.$loading.spinner = true
      if (!this.venue?.isNew) {
        if (this.selectedGroup?._id) {
          this.params.parentId = this.selectedGroup?._id
        }
        const response = await GroupClusterService.getAll(this.venue?._id, this.params)

        if (response.data?.data) {
          this.mediaItems = concat(this.mediaItems, response.data?.data)
          this.mediaItems = uniqBy(this.mediaItems, '_id')
          this._mediaItems = cloneDeep(this.mediaItems)
          this.lastPageFull = response.data?.data?.length === this.params.limit
          if (this.isConvertingVideos == false && response.data?.pendingVideoCount > 0) {
            this.isConvertingVideos = true
          }
          this.getColumns()

          if (this.onlyPhotosMediaItems) {
            this.onlyPhotosMediaItems = this.mediaItems.filter(
              (media) => media?.subCategory != MEDIA_TYPE.VIDEO
            )
          }

          if (this.mediaItems?.length === response.data?.count) this.showLoadMoreBtn = false

          if (
            (this.gridType == 'group' || this.mediaItems?.length) &&
            this.mediaItems?.length === response.data?.count
          ) {
            // const nextSection =
            //   this.gridType == 'group' ? 'media' : this.gridType == 'media' ? 'documents' : null
            // if (nextSection) {
            //   this.$emit('loadNextSection', nextSection)
            // }
          }
        }
        if (response.data?.totalPages) this.totalPages = response.data?.totalPages
      }

      this.$loading.spinner = false
    },
    handleScroll: debounce(function () {
      this.onImageScroll()
    }, 200),
    // handleScroll() {
    //   // if (
    //   //   (this.$route.name == 'location.create' || this.$route.name == 'location.edit') &&
    //   //   window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 200
    //   // ) {
    //   this.onImageScroll()
    //   //}
    // },

    /**
     * The function `getDocumentIcon` takes a file URL as input and returns an icon name based on the
     * file extension.
     * @param fileUrl - The `getDocumentIcon` function takes a `fileUrl` parameter, which is a URL
     * pointing to a file. The function then determines the file type based on the file extension in
     * the URL and returns an icon name corresponding to that file type. The icon names are used to
     * display appropriate icons for
     * @returns The function `getDocumentIcon(fileUrl)` returns the icon name based on the file
     * extension extracted from the `fileUrl`. The icon name returned depends on the file extension as
     * follows:
     * - For PDF files: 'file-pdf-box'
     * - For DOC, DOCX, or TXT files: 'file-document-box'
     * - For XLS or XLSX files: 'microsoft-excel'
     * -
     */
    getDocumentIcon(fileUrl) {
      if (fileUrl) {
        const fileExtension = fileUrl.split('.').pop().toLowerCase()
        switch (fileExtension) {
          case 'pdf':
            return 'file-pdf-box'
            break

          case 'doc':
          case 'docx':
          case 'txt':
            return 'file-document-box'
            break

          case 'xls':
          case 'xlsx':
            return 'microsoft-excel'
            break

          case 'ppt':
          case 'pptx':
            return 'microsoft-powerpoint'
            break

          default:
            return 'file-document-box'
            break
        }
      }
    },

    async setCoverPhoto(media) {
      const index = findIndex(this.mediaItems, { _id: media?._id })
      if (this.$refs?.imageBox?.length) this.$refs?.imageBox[index]?.classList?.remove('selected')

      if (this.mediaItems?.length) {
        const hasCover = some(this.mediaItems, ['isCover', true])

        if (hasCover) {
          this.mediaItems = this.mediaItems.map((item) => ({
            ...item,
            isCover: false
          }))
        }

        const response = GroupClusterService.setCoverImage(this.venue._id, media?._id)
        if (response) {
          this.mediaItems[index].isCover = true
        }
      }
    },

    showAllMedias() {
      this.showLoadMoreBtn = false
      this.onImageScroll()
    },

    toggleChangeRankFeature() {
      this.enabledChangeRank = !this.enabledChangeRank
      this.sortable?.option('disabled', !this.sortable.option('disabled'))
      this.selectedItemsArray = []
      this.getSelectedItems()
      this.deselectAll()
    }
  }
}
