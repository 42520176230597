<template>
  <v-responsive v-if="responsive" :aspect-ratio="aspectRatio">
    <img
      v-if="src"
      v-bind="$attrs"
      :style="getStyle()"
      :class="getClass()"
      :alt="altText"
      :src="srcValue"
      :loading="loading"
      @error="onerror"
    />

    <slot />
  </v-responsive>
  <img
    v-else
    v-bind="$attrs"
    :style="getStyle()"
    :class="getClass()"
    :alt="altText"
    :src="srcValue"
    :loading="loading"
    @error="onerror"
  />
</template>

<script>
export default {
  name: 'VxImg',

  props: {
    auto: { type: Boolean, default: false },
    absolute: { type: Boolean, default: true },
    rounded: { type: Boolean, default: false },
    src: { type: String, default: '' },
    alt: { type: String, default: 'SuperScout' },
    width: { type: String, default: '100%' },
    height: { type: String, default: '100%' },
    placeholder: { type: Boolean, default: false },
    hero: { type: Boolean, default: false },
    banner: { type: Boolean, default: false },
    square: { type: Boolean, default: false },
    rectangle: { type: Boolean, default: false },
    contain: { type: Boolean, default: false },
    cover: { type: Boolean, default: false },
    fill: { type: Boolean, default: false },
    classes: { type: Object, required: false },
    responsive: { type: Boolean, default: true },
    preview: { type: Boolean, default: false },
    loading: { type: String, default: 'lazy' },
    contentType: { type: String, default: '' }
  },

  data() {
    return {
      srcValue: this.src,
      lightbox: null,
      altText: this.alt
    }
  },
  created() {
    if (this.alt === 'SuperScout' && this.src) {
      let url = new URL(this.src)
      const params = new URLSearchParams(url.search)
      this.altText = params.get('alt') || 'SuperScout'
    }
  },

  computed: {
    aspectRatio() {
      if (this.square) {
        return 1
      } else if (this.hero) {
        if (this.breakpoint.xs) {
          return 1
        } else if (this.breakpoint.mdAndDown) {
          return 16 / 9
        } else {
          return 10 / 4
        }
      } else if (this.banner) {
        if (this.breakpoint.mdAndDown) {
          return 16 / 9
        } else {
          return 10 / 4
        }
      } else if (this.rectangle) {
        return 16 / 9
      } else {
        return this.$attrs['aspect-ratio']
      }
    }
  },

  watch: {
    src(value) {
      this.srcValue = value
    }
  },

  methods: {
    getStyle() {
      const object = {
        width: this.width,
        height: this.height
      }

      if (this.contain) {
        object['object-fit'] = 'contain'
      }

      if (this.cover) {
        object['object-fit'] = 'cover'
      }
      if (this.fill) {
        object['object-fit'] = 'fill'
      }
      if (this.placeholder) {
        object.background = '#cccccc'
      }

      return object
    },

    getClass() {
      return {
        rounded: this.rounded,
        'position-absolute all': this.absolute,
        'ma-auto d-block': this.auto,
        'cursor-pointer': this.preview,
        ...this.classes
      }
    },

    onClick() {
      if (this.preview) {
        this.lightbox = 0
      }

      this.$emit('click')
    },
    onerror() {
      const match = this.srcValue.match('/400/|/800/|/1600/')
      const matchOriginal = this.srcValue.match('/original/')
      if (matchOriginal && matchOriginal[0]) {
        this.srcValue = ["image/heic", 'image/heif'].includes(this.contentType) ? `${this.assetURL}/HEICPlaceholder.png` : `${this.assetURL}/placeholder.png`
      } else if (match && match[0]) {
        this.srcValue = this.srcValue.replace(match[0], '/original/')
      }
    }
  }
}
</script>
