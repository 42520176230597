import Api from './api/api.service'

const baseServiceUrl = (locationId) => `location/${locationId}/cluster`

export default {
  getAll(locationId, params) {
    return Api().get(`${baseServiceUrl(locationId)}/all`, { params })
  },
  deleteAll(locationId, payload) {
    return Api().patch(`${baseServiceUrl(locationId)}/remove`, payload)
  },
  setCoverImage(locationId, id, payload) {
    return Api().patch(`${baseServiceUrl(locationId)}/cover/${id}`, payload)
  },
  changeRank(locationId, parentId, category, payload, options = {}) {
    let params = {}
    if (parentId) {
      params.parentId = parentId
    }
    return Api(options).post(`${baseServiceUrl(locationId)}/${category}/changeRank`, payload, { params })
  },
  deleteMany(locationId, payload, options = {}) {
    return Api(options).patch(`${baseServiceUrl(locationId)}/remove/items`, payload);
  }
}
