<template>
  <nav
    class="flex w-full md:max-w-2xl lg:max-w-2xl xl:max-w-7xl md:overflow-x-auto scrollbar-hide"
    aria-label="Breadcrumb"
  >
    <ol role="list" class="flex items-center space-x-4">
      <li
        v-for="(page, index) in pages"
        :key="index"
        :class="index > 0 && `ml-1 w-1/${pages.length - 1}`"
      >
        <div v-if="index == 0 && page?.routeName == 'home'" class="flex items-center">
          <div>
            <router-link
              class="flex items-center cursor-pointer font-bold text-primary"
              :to="{ path: '/' }"
            >
              <div class="md:block hidden">{{ page?.text }}</div>
              <div class="block md:hidden">
                <Icon class="font-bold size-6 text-gray" icon="mdi-magnify" />
              </div>
            </router-link>
          </div>
        </div>
        <div v-else class="flex items-center">
          <svg
            v-if="index != 0"
            class="h-full w-6 flex-shrink-0 text-gray-300"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <router-link
            :class="[
              $route?.name == page?.routeName && !page?.text
                ? 'disabled  line-clamp-1'
                : 'truncate',
              localThemeColorMode === 'dark' ? 'text-gray-300' : 'text-primary'
            ]"
            class="ml-3 cursor-pointer text-md font-bold py-4 select-text-wrap"
            :to="{ path: page.link }"
          >
            {{ page.text }}
          </router-link>
        </div>
      </li>
    </ol>
  </nav>
</template>
<script>
import some from 'lodash/some'
import pullAt from 'lodash/pullAt'
import findIndex from 'lodash/findIndex'
import slice from 'lodash/slice'
import { useRouter } from 'vue-router'
export default {
  props: {
    breadCrumbName: { type: Object, default: {} },
    themeColorMode: { type: String, default: 'dark' }
  },

  data() {
    return {
      pages: [],
      localThemeColorMode: ''
    }
  },

  async mounted() {
    if (
      ![
        'location.share.view',
        'location.share.preview',
        'location.share.photos.preview',
        'share.focus.view'
      ].includes(this.$route.name)
    ) {
      this.localThemeColorMode = null
    } else {
      this.localThemeColorMode = this.themeColorMode
    }
    const router = useRouter()

    const matchedRoutes = router.currentRoute.value.matched
    const previousRoute = localStorage.getItem('previous-route') || {}
    if (!previousRoute?.name) {
      if (
        ![
          'location.share.preview',
          'location.share.view',
          'location.share.photos.preview',
          'location.view',
          'photos.view'
        ].includes(this.$route?.name)
      ) {
        this.pages = JSON.parse(localStorage.getItem('breadcrumbItems')) || []
      }
    }
    //if (this.pages?.length) await this.manageBreadcrumbPages() // Comment because show current last item in breadcrumb

    if (!this.pages?.length) {
      this.pages = matchedRoutes
        .filter((item) => item.meta.showBreadcrumb !== false)
        .map((route, index) => ({
          link: route.meta?.link ? route.meta?.link(this.$route) : '',
          text: this.breadCrumbName ? route.meta?.breadcrumb(this.breadCrumbName[route?.name]) : '',
          routeName: route.name,
          hideHomeBreadcrumb: route.meta.hideHomeBreadcrumb || false
        }))

      const hasHideHomeBreadcrumb = some(this.pages, { hideHomeBreadcrumb: true })

      if (hasHideHomeBreadcrumb) {
        const homeIndex = findIndex(this.pages, { routeName: 'home' })

        if (homeIndex !== -1) {
          pullAt(this.pages, homeIndex)
        }
      }
    }
  },

  methods: {
    manageBreadcrumbPages() {
      const matchedIndex = findIndex(this.pages, { slug: this.$route?.params?.collectionId })
      if (matchedIndex !== -1 && matchedIndex !== this.pages.length - 1) {
        this.pages = slice(this.pages, 0, matchedIndex + 1)
      }
    }
  }
}
</script>
<style scoped>
.disabled {
  pointer-events: none;
}
</style>
