<template>
  <v-avatar :size="size" :color="color" v-bind="$attrs">
    <VxImg
      v-if="src || $userHasImage(user)"
      class="border-radius-inherit"
      :square="square"
      :responsive="responsive"
      :alt="user.firstName"
      :src="src || $getUserImage(user, '100')"
    />
    <span v-else class="light--text text-h5">{{ initialName(user) }}</span>
  </v-avatar>
</template>

<script>
import { ImageMixin } from '@mixins'
import VxImg from '@components/VxImg.vue'

export default {
  name: 'UserAvatar',
  mixins: [ImageMixin],
  components: {
    VxImg
  },
  props: {
    user: { type: Object, required: true },
    size: { type: Number, default: 40 },
    src: { type: String, required: false },
    enabled: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    square: { type: Boolean, default: true },
    color: { type: String, default: 'grey' },
    responsive: { type: Boolean, default: true }
  }
}
</script>
